<template>
  <section class="org-list">
    <a-table
      ref="dictionaryListRef"
      :columns="columns"
      :data-source="dictionaryList"
      rowKey="id"
      :scroll="{
        x: tableWidth,
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template #operation="{ record }">
<!--        <a-space>
          <span
            class="operationLink underline"
            @click="editClick(record)"
            v-auth="['system:roleMaintain:review']"
          >
            Review
          </span>
        </a-space>-->
        <ActionBtn v-bind="record" :btnList="btnList" @IdcardOutlined="editClick(record)" />
      </template>
    </a-table>
  </section>
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: 'Review',
    icon: 'IdcardOutlined',
    auth: ['system:roleMaintain:review']
  },
]
export default defineComponent({
  name: "OrganizationList",
  components: {ActionBtn},
  emits: ["edit-click", "getSize"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const dictionaryListRef = ref();
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch();
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch();
      },
    });
    const dictionaryList = ref();
    let searchCondition = ref({});
    const sort = reactive({
      order: "",
      field: "",
    });

    const doSearch = () => {
      let beginDate, endDate;
      if (
        searchCondition.value.reviewTime &&
        searchCondition.value.reviewTime.length === 2
      ) {
        beginDate = searchCondition.value.reviewTime[0].format("YYYY-MM-DD");
        endDate = searchCondition.value.reviewTime[1].format("YYYY-MM-DD");
      }
      const params = {
        name: searchCondition.value.name ? searchCondition.value.name : "",
        lineManager: searchCondition.value.lineManager
          ? searchCondition.value.lineManager
          : "",
        // orgFourTwo: searchCondition.value.orgFourTwo,
        orgThreeTwo: searchCondition.value.orgThreeTwo,
        orgFourOneOne: searchCondition.value.orgFourOneOne,
        grade: searchCondition.value.grade,
        positionId: searchCondition.value.positionId,
        workLocation: searchCondition.value.workLocation,
        beginDate,
        endDate,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        // {order: 'ascend', field: 'position'}
        // {order: 'descend', field: 'skillPositionOperationTime'}
        sort,
      };
      $api.getRoleMaintainList(params).then((res) => {
        pagination.current = res.data?.pageIndex + 1;
        pagination.pageSize = res.data?.pageSize;
        pagination.total = res.data?.total;
        dictionaryList.value = res.data?.resource || [];
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition.value = searchParams;
      doSearch();
    };

    const handleTableChange = (pag, filters, sorter) => {
      if (!sorter.order) {
        sorter.order = "";
        sorter.field = "";
      }
      Object.assign(sort, sorter);
      doSearch();
    };

    const editClick = (record) => {
      ctx.emit("edit-click", record);
    };

    const columns = [
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        ellipsis: true,
        width: 110,
        fixed: "left",
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        key: "ggid",
        ellipsis: true,
        width: 110,
        fixed: "left",
      },
      {
        title: "Grade",
        dataIndex: "psGroup",
        key: "psGroup",
        ellipsis: true,
        width: 80,
        fixed: "left",
      },
      {
        title: "LM",
        dataIndex: "lmName",
        key: "lmName",
        ellipsis: true,
        width: 100,
      },
      /* {
        title: "Org 4.2",
        dataIndex: "orgFourTwo",
        key: "orgFourTwo",
        width: 150,
        ellipsis: true,
      }, */
      {
        title: "Team",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Offering",
        dataIndex: "orgFourOneOne",
        key: "orgFourOneOne",
        width: 180,
        ellipsis: true,
      },
      {
        title: "Work City",
        dataIndex: "workLocation",
        key: "workLocation",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Role",
        dataIndex: "position",
        key: "position",
        ellipsis: true,
        sorter: true,
        width: 150,
      },
      {
        title: "Reviewer",
        dataIndex: "skillOperatorName",
        key: "skillOperatorName",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Review Time",
        dataIndex: "skillPositionOperationTime",
        key: "skillPositionOperationTime",
        ellipsis: true,
        sorter: true,
        width: 150,
      },
      {
        title: "操作",
        dataIndex: "operation",
        slots: { customRender: "operation" },
        width: 50,
        fixed: "right",
      },
    ];

    const tableHeight = ref(0);
    const bodyWidth = document.body.clientWidth;
    const tableWidth = bodyWidth - (93 / 1920) * bodyWidth;
    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
      console.log("tableHeight.value", tableHeight.value);
    };
    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      dictionaryListRef,
      columns,
      pagination,
      dictionaryList,
      searchClick,
      doSearch,
      tableHeight,
      editClick,
      handleTableChange,
      tableWidth,
      getSize,
      btnList
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  overflow: auto;
}

:deep(.ant-table-thead) {
  > tr > th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  > tr > td {
    white-space: nowrap;
  }
}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
}
</style>

<template>
  <section class="app-container">
    <Filter
      @search="handleSearch"
      @refresh-table="refreshTable"
      @getSize="getSize"
    />
    <DictionaryList ref="dictionaryListRef" @edit-click="editClick">
    </DictionaryList>
    <Review
      ref="reviewRef"
      @refresh-table="refreshTable"
      :getId="getId"
      :role="role"
    />
    <!-- <emp-info 
      :title="'技能岗位审核'"
      :getId="getId" 
      v-if="isShowingEmpInfo"
      :isShowingEmpInfo="isShowingEmpInfo"
      :source="'isShow'"
      :showworkExperienceCol="false"
      :role="role"
      @close-emp-info="closeEmpInfo"
      @refresh-table="refreshTable"
      /> -->
  </section>
</template>
<script>
import { defineComponent, ref, nextTick } from "vue";
import Filter from "./components/Filter";
import DictionaryList from "./components/List";
import Review from "./components/Review";

export default defineComponent({
  name: "roleMaintain",
  components: {
    Review,
    DictionaryList,
    Filter,
  },
  setup() {
    const dictionaryListRef = ref();

    let searchCondition = ref({});
    const handleSearch = (searchParams) => {
      if (searchParams) {
        searchCondition.value = searchParams;
      }
      dictionaryListRef.value.searchClick(searchCondition.value);
    };

    const reviewRef = ref();
    const getId = ref("");
    // const userId = ref("");
    const role = ref("");
    const editClick = (item) => {
      // userId.value = item.id;
      getId.value = item.id;
      role.value = item.positionId;
      // console.log(reviewRef.value.openModal);
      nextTick(reviewRef.value.openModal);
    };

    //关闭弹窗
    // const closeEmpInfo = () => {
    //   isShowingEmpInfo.value = false;
    // };
    const getSize = () => {
      dictionaryListRef.value.getSize();
    };
    const refreshTable = () => {
      dictionaryListRef.value.doSearch();
    };

    return {
      dictionaryListRef,
      handleSearch,
      editClick,
      getId,
      searchCondition,
      refreshTable,
      role,
      reviewRef,
      getSize, // userId,
    };
  },
});
</script>

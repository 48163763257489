<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout
        @doReset="resetForm"
        @doSearch="doSearch"
        ref="filterLayoutRef"
        @doCollapseEffect="getChartContainerStyle"
        @showMoreForm="showMoreForm"
        :moreBtnVisible="moreBtnVisible"
        :isShowMoreFlag="isShowMoreList"
      >
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index<=4">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='2'">
                    <a-select
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        :placeholder="getPlaceHoder(item.inputDataType)"
                        allowClear
                        show-search
                    >
                    </a-select>
                  </template>
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formState[item.inputDataType]"
                        :placeholder="getPlaceHoder(item.inputDataType)"
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='6'">
                    <a-range-picker
                        v-model:value="formState.reviewTime"
                        :placeholder="['开始时间', '结束时间']"
                    />
                  </template>
                </a-form-item>
              </template>
            </template>
            <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index>4">
                  <a-form-item :label="item.code" name="name" class="rms-form-item">
                    <template v-if="item.type==='2'">
                      <a-select
                          v-model:value="formState[item.inputDataType]"
                          :options="getOptions(item.inputDataType)"
                          :placeholder="getPlaceHoder(item.inputDataType)"
                          allowClear
                          show-search
                      >
                      </a-select>
                    </template>
                    <template v-if="item.type==='3'">
                      <a-input
                          v-model:value="formState[item.inputDataType]"
                          :placeholder="getPlaceHoder(item.inputDataType)"
                          allowClear
                          autocomplete="off"
                      />
                    </template>
                    <template v-if="item.type==='6'">
                      <a-range-picker
                          v-model:value="formState.reviewTime"
                          :placeholder="['开始时间', '结束时间']"
                      />
                    </template>
                  </a-form-item>
                </template>
              </template>
            </MoreFormList>
          </a-form>
        </template>
      </FilterLayout>
    </div>
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="true" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </section>
</template>
<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  onActivated,
  watch,
  nextTick
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import {dictionaryOpt, dictionaryOptions, dictionaryOptionsCode} from "@/utils/common";
import { cloneDeep } from "lodash";
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'

export default defineComponent({
  name: "TopFilter",
  components: {MoreFormList,FormModalBox},
  emits: ["search", "refresh-table"],
  props: {},
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const formRef = ref();
    const resetForm = () => {
      formRef.value.resetFields();
      Object.assign(formState,initData)
      updateParams();
      ctx.emit("search", currentState);
    };
    let currentState = {};
    const updateParams = () => {
      currentState = cloneDeep(formState);
      console.log(currentState, 'currentStatecurrentState')
    };
    const initData = {
      name: "",
      lineManager: "",
      orgFourTwo: undefined,
      grade: undefined,
      positionId: undefined,
      workLocation: undefined,
      reviewTime: [],
      orgThreeTwo: undefined,
      orgFourOneOne: undefined,
    }
    const formState = reactive({...initData});
    const doSearch = () => {
      nextTick(() => {
        updateParams();
        ctx.emit("search", currentState);
      });
    };

    const orgFourTwoOps = ref([]);
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const gradeOps = ref([]);
    const roleOps = ref([]);
    const workCityOps = ref([]);

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      $api.queryFilterSort({
        firstMenu: 'skillAndPositionReview',
        secondMenu: '',
        isQueryDefault: data.isQueryDefault
      }).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 5?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getOrgList = () => {
      //查询org3.2/org4.2
      $api.getOrgThree_Four_RoleMaintainList().then((res) => {
        const { orgThreeTwo, orgFourOneOne } = res.data;
        orgThreeTwoOps.value = dictionaryOpt(orgThreeTwo);
        orgFourOneOneOps.value = dictionaryOpt(orgFourOneOne);
      });
    };

    // 获取筛选框字典
    const getSelectOptions = () => {
      /* $api.getOrgFourTwo().then((res) => {
        orgFourTwoOps.value = res.data.orgFourTwo;
      }); */
      getOrgList();
      $api
        .getDictionaryList({
          params: {
            dictType: "grade,role,city",
          },
        })
        .then((res) => {
          gradeOps.value = dictionaryOptions(res.data.grade);
          roleOps.value = dictionaryOptionsCode(res.data.role);
          workCityOps.value = dictionaryOptions(res.data.city);
        });
    };
    const getOptions = (data)=> {
      switch (data) {
        case 'orgThreeTwo':
          return orgThreeTwoOps.value
        case 'orgFourOneOne':
          return orgFourOneOneOps.value
        case 'grade':
          return gradeOps.value
        case 'workLocation':
          return workCityOps.value
        case 'positionId':
          return roleOps.value
        default :
          ''  // 如果没
      }
    }
    const getPlaceHoder = (data)=> {
      switch (data) {
        case 'name':
          return 'Name/GGID'
        case 'lineManager':
          return 'LM'
        default :
          return '请选择'  // 如果没
      }
    }
    watch(
      () => formState.orgThreeTwo,
      () => {
        formState.orgFourOneOne = undefined;
        if (formState.orgThreeTwo) {
          $api
            .getRoleMaintainOrgFour({ condition: formState.orgThreeTwo })
            .then((res) => {
              orgFourOneOneOps.value = dictionaryOpt(res.data.orgFourOneOne);
            });
        } else {
          getOrgList();
        }
      }
    );
    const getChartContainerStyle = () => {
      nextTick(() => {
        ctx.emit("getSize");
      });
    };
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        formState.positionId = undefined
        formState.lineManager = ''
        formState.reviewTime = []
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      getSelectOptions();
      // doSearch();
      getChartContainerStyle();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });

    onActivated(() => {
      nextTick(() => {
        // doSearch();
        ctx.emit("refresh-table");
      });
    });
    return {
      queryFormList,
      queryList,
      checkList,
      getOptions,
      getPlaceHoder,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      getChartContainerStyle,
      formState,
      formRef,
      doSearch,
      resetForm,
      orgFourTwoOps,
      orgThreeTwoOps,
      orgFourOneOneOps,
      gradeOps,
      roleOps,
      workCityOps,
      updateParams,
      moreSeachHanlder
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
}
</style>

<template>
  <section ref="staffingModal">
    <a-drawer
      class="staffing-modal"
      v-model:visible="visible"
      title="技能新增"
      width="38.5vw"
      @close="closeModal"
    >
      <PerfectScrollbar>
        <div class="dialog">
          <div class="title-child">
            <a-checkbox-group v-model:value="selectIdList">
              <a-checkbox
                class="title-type"
                v-for="item in skillList"
                :value="item.skillId"
                :key="item.skillId"
              >
                <div class="categories" :title="item.skillName">
                  {{ item.skillName }}
                </div>
              </a-checkbox>
            </a-checkbox-group>
          </div>
        </div>
      </PerfectScrollbar>
      <div class="btn bottom-btn">
        <a-button type="primary" ghost class="editButton" @click="closeModal">取消</a-button>
        <a-button type="primary" class="editButton border" @click="submitClick">确定</a-button>
      </div>
    </a-drawer>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "SelectDeptSkill",
  components: {},
  emits: ["reset-skill"],
  props: {
    skillDept: String,
    selectSkillList: Array,
    editClick: Boolean,
  },
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const visible = ref(false);
    const skillList = ref([]);
    const selectIdList = ref([]);

    const openModal = () => {
      selectIdList.value = props.selectSkillList.map((item) => item.skillId);
      const params = {
        category: props.skillDept,
        skillDept: props.skillDept,
      };
      $api.getSkillList(params).then((res) => {
        skillList.value = res.data;
        visible.value = true;
      });
    };

    let submitClick = () => {
      const selectList = [];
      selectIdList.value.forEach((skillId) => {
        let star;
        if (props.selectSkillList) {
          star = props.selectSkillList.find((item) => item.skillId === skillId);
        }
        if (star) {
          selectList.push(star);
        } else {
          const newSkill = skillList.value.find(
            (item) => item.skillId === skillId
          );
          selectList.push(newSkill);
        }
      });
      ctx.emit("reset-skill", selectList);
      closeModal();
      props.editClick();
    };

    const closeModal = () => {
      visible.value = false;
      skillList.value = [];
      selectIdList.value = [];
    };

    return {
      visible,
      skillList,
      selectIdList,
      openModal,
      submitClick,
      closeModal,
    };
  },
});
</script>
<style scoped lang="less">
.dialog{
  position: relative;
  padding: 24px 0;
  height: calc(100% - 122px);
  padding-bottom: 64px;
  overflow: auto;
  .btn {
    position: absolute;
    bottom: 0;
    padding: 14px 24px;
    width: 100%;
    text-align: right;
    border-top: 1px solid #ebebeb;
    z-index: 1000;
    .editButton{
      width: 84px;
    }
    .border {
      margin-left: 10px;
    }
  }
}

.skillBox {
  overflow: hidden;
  //overflow-anchor: none;
  //touch-action: auto;
  &:hover {
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

.box {
  height: 400px;
  position: relative;
  max-height: 400px;

  .title {
    word-wrap: break-word;
    width: 200px;
    height: 80px;
    color: #999;
    text-align: center;
    position: relative;

    .menu {
      margin-top: 20px;
      font-size: 20px;
      line-height: 23px;
      width: 200px;
      text-align: center;
      // height: 70px;
      // max-height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .actives {
    color: #1890ff;
  }
}

.title-child {
  left: 100px;
  display: block;
  height: 100%;
  background-color: white;
  padding-top: 15px;
  overflow: hidden;
  flex: 1;

  &:hover {
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }

  .title-type {
    border: 2px solid #999;
    border-radius: 20px;
    color: #999;
    width: 200px;
    height: 30px;
    float: left;
    margin-left: 40px;
    margin-bottom: 40px;
    cursor: pointer;
    position: relative;

    .categories {
      font-size: 18px;
      word-wrap: break-word;
      width: 200px;
      text-align: center;
      max-height: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  :deep(.ant-checkbox-wrapper-checked) {
    border-color: #1890ff;
    color: #1890ff;
  }

  :deep(.ant-checkbox) {
    display: none;
  }
}
</style>

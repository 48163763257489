<template>
  <div id="ctf" ref="ctf">
    <div class="update-button">
      <a-space>
        <a-upload
          name="file"
          :accept="accept"
          :multiple="true"
          action=""
          :showUploadList="false"
          :beforeUpload="beforeUpload"
          :customRequest="() => { }"
        >
          <a-button type="primary" ghost :disabled="tableList.length === 4" v-auth="['system:roleMaintain:upload']">上传简历</a-button>
        </a-upload>
        <a-button type="primary" @click="downloadCV" :disabled="tableList.length === 0" v-auth="['system:roleMaintain:downloadAll']">全部下载</a-button>
      </a-space>
    </div>
    <div class="table">
        <a-table
          :data-source="tableList"
          size="small"
          :columns="columns"
          :rowKey="(record) => record.id"
          class="gray-table"
          :rowClassName="
            (record, index) => (index / 1 === index ? 'table-striped' : null)
          "
          :pagination="false"
        >
          <template #icon="{ record, text }">
            <FilePptFilled class="ppt" v-if="record.file_name.indexOf('ppt') !== -1"/>
            <FileWordFilled class="word" v-else />
            {{ text }}
          </template>
          <template #operation="{ record }">
            <a-space>
              <a-tooltip class="tooltip-list" placement="top" color="#fff">
                <template #title>
                  <span class="font-color">上传</span>
                </template>
                <span @click="onselectFun(record)" style="display: inline-block;height:100%;">
                  <a-upload
                    class="upload-btn"
                    name="file"
                    :accept="accept"
                    action=""
                    :showUploadList="false"
                    :beforeUpload="beforeUpdate"
                    :customRequest="update"
                  >
  <!--                  <span class="operationLink underline" v-auth="['system:roleMaintain:update']">更新</span>-->
                    <a-button class="border-none" v-auth="['system:roleMaintain:update']"><UploadOutlined :style="{color: '#3182CE'}" /></a-button>
                  </a-upload>
                </span>
              </a-tooltip>
<!--              <span class="operationLink underline" @click="download(record)" v-auth="['system:roleMaintain:download']">下载</span>
              <span class="operationLink underline" @click="preview(record)" v-auth="['system:roleMaintain:preview']">预览</span>-->
              <ActionBtn v-bind="record" :btnList="btnList" @DownloadOutlined="download(record)" @FileImageOutlined="preview(record)" />
            </a-space>
          </template>
        </a-table>
    </div>
    <a-modal
        v-model:visible="previewVisible"
        :getContainer="()=>$refs.ctf"
        title="预览"
        width="62.5vw"
        centered
        @cancel="closePreviewModal"
    >
      <template #footer>
        <div class="footerBtn">
          <a-button @click="closePreviewModal">取消</a-button>
          <a-button @click="download(previewDoload)" type="primary" v-auth="['system:roleMaintain:download']">下载</a-button>
        </div>
      </template>
      <iframe id="printIframe"
              :src="previewUrl"
              style="width: 100%; height: 70vh;"></iframe>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { message } from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { downloadFile } from "@/utils/common";
import { FilePptFilled, FileWordFilled, UploadOutlined } from "@ant-design/icons-vue";
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '下载',
    icon: 'DownloadOutlined',
    auth: ['system:roleMaintain:download']
  },
  {
    name: '预览',
    icon: 'FileImageOutlined',
    auth: ['system:roleMaintain:preview']
  },
]
export default defineComponent({
  name: "ResumeManagement",
  components: {
    FilePptFilled,
    FileWordFilled,
    UploadOutlined,
    ActionBtn
  },
  props: {
    userId: String,
  },
  emits: ['downloadCV'],

  setup(props, ctx) {
    console.log(props.userId);
    const { $api } = useGlobalPropertyHook();
    const tableList = ref([]);

    const columns = [
      {
        title: "简历名称",
        dataIndex: "file_name",
        ellipsis: true,
        slots: { customRender: "icon" },
      },
      {
        title: "更新时间",
        dataIndex: "uploadTime",
        ellipsis: true,
      },
      {
        title: "操作",
        dataIndex: "operation",
        ellipsis: true,
        width: 140,
        slots: { customRender: "operation" },
      },
    ];

    const downloadCV = () => {
      ctx.emit('downloadCV')
    }

    //获取简历列表
    const getResume = () => {
      $api.getResumeList(props.userId).then((res) => {
        tableList.value = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    };

    const fileData = ref([]);
    let errorNum = 0;
    const accept = ref(`
      .doc, .DOC,
      .docx, .DOCX,
      .ppt, .PPT,
      .pptx, .PPTX
    `);

    //RM、PM代上传简历
    const customRequest = () => {
      const formData = new FormData();
      fileData.value.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("userId", props.userId);
      $api.postUpload(formData).then((res) => {
        getResume();
        message.success(res.msg);
        fileData.value = [];
        errorNum = 0;
      })
      .catch((err) => {
        console.log(err);
        fileData.value = [];
        errorNum = 0;
      });
    };
    // 上传前文件格式
    const beforeUpload = (file, list) => {
      return new Promise(() => {
        const fileType = file.name.substring(
          file.name.lastIndexOf(".") + 1,
          file.name.length
        );
        const isLt10M = file.size / 1024 / 1024 < 10;
        const isGt0KB = file.size > 0;
        if (!isLt10M) {
          message.error("文件最大10MB!");
          errorNum++;
          if (errorNum == list.length) errorNum = 0;
        } else if (!isGt0KB) {
          message.error("不能上传0KB的文件!");
          errorNum++;
          if (errorNum == list.length) errorNum = 0;
        } else if (
          ![
            "doc", "DOC", "docx", "DOCX", "ppt", "PPT", "pptx", "PPTX",
          ].includes(fileType)
        ) {
          message.error(
            file.name +
              " 文件格式错误！" +
              "只能上传doc、docx、ppt、pptx格式的文件"
          );
          errorNum++;
          if (errorNum == list.length) errorNum = 0;
        } else {
          fileData.value.push(file);
        }
        if (
          fileData.value?.length &&
          fileData.value.length == list.length - errorNum
        ) {
          // 上传
          customRequest();
        }
      });
    };


    // 简历id
    const fileId = ref()
    const onselectFun = (e) => {
      fileId.value = e.id
    }

    //更新
    const update = async (file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("userId", props.userId);
      formData.append("id", fileId.value);
      await $api.postUpdate(formData)
      getResume();
      message.success("更新成功")
    }
    //更新前文件格式
    const beforeUpdate = (file, list) => {
      return new Promise(() => {
        const fileType = file.name.substring(
          file.name.lastIndexOf(".") + 1,
          file.name.length
        );
        const isLt10M = file.size / 1024 / 1024 < 10;
        const isGt0KB = file.size > 0;
        if (!isLt10M) {
          message.error("文件最大10MB!");
          errorNum++;
          if (errorNum == list.length) errorNum = 0;
        } else if (!isGt0KB) {
          message.error("不能上传0KB的文件!");
          errorNum++;
          if (errorNum == list.length) errorNum = 0;
        } else if (
          ![
            "doc", "DOC", "docx", "DOCX", "ppt", "PPT", "pptx", "PPTX",
          ].includes(fileType)
        ) {
          message.error(
            file.name +
              " 文件格式错误！" +
              "只能上传doc、docx、ppt、pptx格式的文件"
          );
          errorNum++;
        }else{
          //更新
          update(file);
        }
      });
    };

    //下载
    const download = (item) => {
      console.log(item, '123')
      $api.getOneCV(item.id, item.file_name).then((res) => {
        downloadFile(res.file, item.file_name);
      });
    };

    //预览
    const previewVisible =ref(false)
    const previewUrl = ref()
    const previewDoload = ref()
    const preview = async (item) => {
      previewDoload.value = item
      console.log(item, '999')
      const { data } = await $api.reviewCV(item.id)
      previewVisible.value = true
      previewUrl.value = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(data)}`;
      //预览弹框下载传参
    }

    const closePreviewModal = () => {
      previewVisible.value = false
      previewUrl.value = ""
      previewDoload.value = ''
    }

    onMounted(() => {
      getResume();
    });

    return {
        tableList,
        columns,
        getResume,
        downloadCV,
        accept,
        beforeUpload,
        customRequest,
        update,
        download,
        preview,
        previewVisible,
        previewUrl,
        closePreviewModal,
        beforeUpdate,
        onselectFun,
        previewDoload,
        btnList
    }
  }

})
</script>
<style scoped lang="less">
@import "~@/style/aModal.less";
.ant-modal-header {
  height: 80px;
  line-height: 80px;
}

#ctf {
  margin-top: -30px;
  padding-top: 60px;
  position: relative;
  margin-left: 15px;
  .update-button {
    position: absolute;
    right: 0;
    top: 0;
    .edit-button {
      width: 90px;
    }
    .ant-btn{
      padding: 0 16px;
    }
  }
  .table {
    position: relative;
    .operationLink{
      font-size: 17px;
    }
    .icon {
      position: absolute;
      left: 10px;
      top: 15px;

      ::v-deep svg {
        width: 24px;
        height: 24px;
      }
    }
    .edit {
      width: 20px;
      cursor: pointer;
    }

    .ppt {
      color: #f16c41;
    }

    .word {
      color: #4f6bf6;
    }
  }
}
:deep(.ant-space-item){
  display: flex;
  align-items: center;
  margin-right: 6px;
  height: 35px;
}
:deep(.ant-btn){
  &:not(:last-child){
    margin-right: 12px;
  }
  .anticon{
    display: block;
    width: 24px;
    height: 24px;
    svg{
      width: 100%;
      height: 100%;
    }
    &.anticon-delete{
      svg{
        color: #DC5E4F;
      }
    }
    &.anticon-minus-circle{
      svg{
        color: #DC5E4F;
      }
    }
  }
}
.upload-btn{
  :deep(.ant-btn){
    padding: 0;
    background: none;
  }
}
.border-none{
  border: none;
}
.font-color{
  color:#4d4d4d;
}
.footerBtn {
  text-align: right;
  position: relative;
}
</style>

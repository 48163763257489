<template>
    <div id="ctf">
        <span v-show="!isChange" class="role">{{ chooseText }}</span>
        <a-select
          v-show="isChange"
          class="role-select"
          v-model:value="chooseRole"
          :options="roleOps"
          placeholder="请选择"
          allowClear
        >
        </a-select>
      <div class="button">
          <a-button class="edit-button" type="primary" v-show="!isChange" @click="()=>{isChange=true}" v-auth="['system:roleMaintain:auditRole']">审核</a-button>
        <a-space v-show="isChange">
          <a-button class="edit-button" type="primary" ghost @click="cancel">取消</a-button>
          <a-button class="edit-button" type="primary" @click="saveClick" v-auth="['system:roleMaintain:saveRole']">保存</a-button>
        </a-space>
      </div>
    </div>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
  </template>
  <script>
  import { defineComponent, ref, onMounted } from "vue";
  import { useGlobalPropertyHook } from "@/hooks/common.js"; 
  import { dictionaryOptionsCode } from "@/utils/common";
  import { message } from "ant-design-vue";
  import ModalBox from '@/components/ModalBox'

  export default defineComponent({
    name: "Role",
    props: {
      userId: String,
      role: String,
      roleStatus: {
        type: Boolean,
        default: false,
      }
    },
    components: {ModalBox},
    emits: ["refresh-table"],
    
    setup(props, {emit}) {
      const { $api } = useGlobalPropertyHook();

      const isChange = ref(false);
      const chooseText = ref();
      const chooseRole = ref();
      const roleOps = ref([]);

      //获取当前人员的role
      const getRole = () => {
        if(props.role){
          chooseRole.value = props.role;
        }
      }
      //获取Role筛选框
      const getRoleOps = () => {
        $api.getDictionaryList({
          params: {
            dictType: "role",
          },
        })
        .then((res) => {
          roleOps.value = dictionaryOptionsCode(res.data.role);
          chooseText.value = roleOps.value.find((item) => item.value === props.role).dictValue
        });
      };
      
      //取消
      const cancel = () => {
        isChange.value = false;
        if (props.role) {
          chooseRole.value = props.role;
        }
      }

      const addFunc = () => {
        const roleLable = roleOps.value.find(
          (item) => item.value === chooseRole.value
        );
        const params = {
          id: props.userId,
          position: roleLable.label,
          positionId: chooseRole.value,
        };
        $api.saveRole(params).then((res) => {
          message.success("Review successfully.");
          chooseRole.value = res.data.positionId;
          chooseText.value = res.data.position;
          emit("refresh-table");
        });
      };
      const modelValue = ref(false)
      const content = ref('')
      const showIconCom = ref('')
      const handleOk = () => {
        addFunc()
      }
      //保存
      const saveClick = () => {
        isChange.value = false;
          if (!chooseRole.value) {
            message.error("请选择Role！");
            return;
          }
        content.value = 'Role是否确定已review？'
        showIconCom.value = 'ExclamationCircleOutlined'
        modelValue.value = true
        /*Modal.confirm({
          title: "Role是否确定已review？",
          icon: createVNode(ExclamationCircleOutlined),
          centered: true,
          closable: true,
          okText: "确定",
          cancelText: "取消",
          onOk() {
            addFunc()
          },
        });*/
      }

      onMounted(() => {
        if(props.roleStatus) {
          isChange.value = props.roleStatus
        }
        getRoleOps();
        getRole();
      });
  
      return {
        isChange,
        roleOps,
        chooseText,
        chooseRole,
        getRoleOps,
        cancel,
        saveClick,
        getRole,
        addFunc,
        modelValue,
        showIconCom,
        content,
        handleOk,
      }
    }
  
  })
  </script>
  <style scoped lang="less">
  .ant-modal-header {
    height: 80px;
    line-height: 80px;
  }
  #ctf {
    margin-top: -30px;
    padding-top: 60px;
    position: relative;
    margin-left: 15px;
   .role{
        color: rgb(53, 177, 248);
        font-size: 0.9vw;
        top:0.01vw;
        left: 3.5vw;
        position: absolute;
    }
    .button {
      position: absolute;
      right: 0;
      top: 0;
      .edit-button {
        width: 84px;
      }
    }
    .role-select {
        width: 300px;
    }
    .ant-select{
        top:-3.2vw;
        left: 3.5vw;
    }
  }
  </style>
  
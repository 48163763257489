<template>
  <div ref="modalRef">
    <a-drawer
      :visible="modalVisible"
      width="62.5vw"
      @close="close"
      :bodyStyle="{ overflow: 'hidden', padding: '0px' }"
      :getContainer="getRefsAff"
    >
      <template #title>
        <strong class="textEmp">技能岗位审核</strong>
      </template>
      <div class="scrollbar">
        <div class="base-info">
          <userBasicInfo
            ref="userBasicInfoRef"
            :userData="state.userData"
            :user-id="getId"
            :isShowRole="isShowRole"
          >
          </userBasicInfo>
        </div>
        <div class="part">
          <div class="part-title">简历管理</div>
          <resumeManagement
            :user-id="getId"
            @downloadCV="downloadCV"
            v-if="modalVisible"
          />
        </div>
        <div class="part">
          <div class="part-title">Role</div>
          <Role
            :user-id="getId"
            :role="roleData"
            :roleStatus="roleStatus"
            v-if="modalVisible"
          />
        </div>
            <div class="part divider">
              <div class="part-title">部门技能</div>
              <div class="staffing-skill">
              <span class="red-text" v-show="hasEmptySkill">技能评分不能为空</span>
              <div class="add-btn">
                <a-space v-if="!delBtnShow">
                  <a-button class="edit-button blue-border-btn" @click="editClick" v-auth="['system:roleMaintain:editSkill']">编辑</a-button>
                  <a-button class="edit-button blue-btn" @click="addSkill" v-auth="['system:roleMaintain:addSkill']">新增</a-button>
                </a-space>
                <a-space v-else>
                  <a-button class="edit-button blue-border-btn" @click="cancel">取消</a-button>
                  <a-button class="edit-button blue-btn" @click="saveClick" v-auth="['system:roleMaintain:saveSkill']">保存</a-button>
                </a-space>
              </div>
              <div class="skillslist">
                <div class="skills">
                  <div
                    v-for="(item, index) in skillsList"
                    :key="item.skillId"
                    :class="['skill-name', delBtnShow ? 'skill-name-hover' : '']"
                  >
                    {{ item.skillName }}
                    <a-rate v-model:value="item.skillStar" class="icon" :disabled="!delBtnShow" @change="showList"/>
                    <CloseCircleOutlined
                      @click="delSkill(index,i)"
                      title="delete"
                      :class="['del-button', delBtnShow ? 'del-button-hover' : '']"
                    />
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
      <SelectDeptSkill
        ref="SelectDeptSkillRef"
        :skill-dept="state.userData.skillDept"
        :select-skill-list="skillsList"
        @reset-skill="resetSkill"
        :editClick="editClick"
      />
    </a-drawer>

  </div>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>

<script>
import { defineComponent, nextTick, watch, reactive, ref,computed} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { CloseCircleOutlined } from "@ant-design/icons-vue";
import { downloadFile } from "@/utils/common";
import { message } from "ant-design-vue";
import SelectDeptSkill from "@/views/home/RoleMaintain/components/SelectDeptSkill";
import userBasicInfo from "@/views/home/UserInfo/components/UserBasicInfo";
import resumeManagement from "@/components/resumeManagement.vue";
import Role from "@/components/Role.vue";
import ModalBox from '@/components/ModalBox'
const tableWidth = window.innerWidth;
const tableHeight = window.innerHeight;
export default defineComponent({
  name: "Review",
  components: {
    SelectDeptSkill,
    CloseCircleOutlined,
    userBasicInfo,
    resumeManagement,
    Role,
    ModalBox
  },
  props: {
    role: String,
    getId: [Number, String],
    userDataList: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["refresh-table"],
  setup(props, {emit}) {
    const { $api } = useGlobalPropertyHook();

    const skillsList = ref([]);
    const originalList = ref([]);
    const roleData=ref('');
    const delBtnShow = ref(false);
    const roleStatus = ref(false);

    const state = reactive({
      userData: {},
    });
    //递归判断打分项是否为空
    function hasZeroSkillStar(data) {
      if (Array.isArray(data)) {
        // 如果 data 是数组，则遍历每个元素并递归调用 hasZeroSkillStar
        for (const item of data) {
          if (hasZeroSkillStar(item)) {
            // 如果有任何一个元素返回 true，则直接返回 true
            return true;
          }
        }
      } else if (typeof data === "object") {
        if (data.skillStar === 0) {
          // 如果 skillStar 为 0，则返回 true
          return true;
        }
      }
      return false;
    }
    const hasEmptySkill = computed(()=>hasZeroSkillStar(skillsList.value));
    //Review-岗位名称不可见
    const isShowRole = ref(true);

    const sortStar = () => {
      skillsList.value.sort((a, b) => {
        // let aName = a.skillName.toLowerCase();
        // let bName = b.skillName.toLowerCase();
        // if (aName < bName) return -1;
        // if (aName > bName) return 1;
        // return 0;
        if (b.skillStar !== a.skillStar) {
            return b.skillStar - a.skillStar;
          } else {
            // 如果 skillStar 相同，则按照 skillName 首字母排序（忽略大小写）
            let aName = a.skillName.toLowerCase();
            let bName = b.skillName.toLowerCase();
            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
          }
      });
    };

    let getSkillsList = () => {
      skillsList.value = [];
      const params = {
        userId: props.getId,
      };
      $api.reviewSkillStars(params).then((res) => {
        originalList.value = skillsList.value = res.data;
        sortStar();
      });
    };

    const getUserData = () => {
      $api.getOneUser(props.getId).then((res) => {
        if (res.code == 200) {
          modalVisible.value = true;
          state.userData = res.data;
        }
      });
    };
    const init = () => {
      if(JSON.stringify(props.userDataList) !== "{}" && props.userDataList) {
        state.userData = props.userDataList
      }else {
        getUserData();
      }
    };

    const modalVisible = ref(false);

    const openModal = () => {
      getSkillsList();
      getUserData();
      userBasicInfoRef.value.getPhoto1()
    };

    const close = () => {
      modalVisible.value = false;
      skillsList.value = [];
      delBtnShow.value = false;
      roleStatus.value = false;
      emit("refresh-table");
    };

    //编辑技能
    const editClick = () => {
      delBtnShow.value = true;
    };
    //技能-取消
    let cancel = () => {
      skillsList.value = [...originalList.value];
      delBtnShow.value = false;
    };
    //保存技能
    const addFunc = () => {
      const params={
        id:props.getId
      }
      $api.saveSkill(skillsList.value, params).then(() => {
        message.success("Review successfully.");
      });
      sortStar()
    };

    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const handleOk = () => {
      addFunc();
    }
    const saveClick = () => {
      if (delBtnShow.value) {
        const isEmpty = skillsList.value.some(skill => !skill.skillStar);
        if (isEmpty) {
          message.error("请确保所有技能都有评分后再保存。");
          return;
        }
      }
      content.value = '部门技能是否确定已review？'
      showIconCom.value = 'ExclamationCircleOutlined'
      modelValue.value = true

      /*Modal.confirm({
        title: "部门技能是否确定已review？",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        onOk() {
          addFunc();
        },
        centered: true,
        closable: true,
      });*/
      delBtnShow.value = false;
    };

    const modalRef = ref();
    const getRefsAff = () => {
      return modalRef.value;
    };

    const downloadCV = () => {
      $api.getZipCV(props.getId).then((res) => {
        if (res.file.type == "application/json") {
          message.error("此人没有简历！");
        } else {
          const fileName = state.userData.fullName + "_简历.zip";
          downloadFile(res.file, fileName);
        }
      });
    };

    const SelectDeptSkillRef = ref();
    //新增技能
    const addSkill = () => {
      nextTick(SelectDeptSkillRef.value.openModal);
    };
    //技能-删除
    let delSkill = (skillIndex) => {
      skillsList.value.splice(skillIndex, 1);
    };

    const resetSkill = (skillList) => {
      skillsList.value = skillList;
      sortStar();
    };

    watch(
      ()=>props.role,
      (val) => {
      roleData.value=val;
    });

    init();
    const userBasicInfoRef = ref()

    return {
      modalVisible,
      openModal,
      close,
      saveClick,
      skillsList,
      modalRef,
      getRefsAff,
      downloadCV,
      addSkill,
      delSkill,
      tableWidth,
      tableHeight,
      resetSkill,
      SelectDeptSkillRef,
      roleData,
      state,
      delBtnShow,
      getUserData,
      editClick,
      cancel,
      isShowRole,
      roleStatus,
      modelValue,
      content,
      showIconCom,
      handleOk,
      userBasicInfoRef,
      hasEmptySkill
    };
  },
});
</script>

<style lang="less" scoped>
.ant-table-thead > tr > th .ant-table-header-column {
  font-weight: 700;
  font-size: 24px;
}

::v-deep {
  .ant-descriptions-item-label {
    font-weight: 600;
    font-size: 21px !important;
  }

  .ant-descriptions-item-container {
    position: relative;
  }
}

:deep(.ant-table-placeholder) {
  display: table;
  width: 100%;
}

:deep(.ant-empty) {
  display: table-cell;
  vertical-align: middle;
}
.threeInfos {

  ::v-deep .ant-table-thead > tr > th.ant-table-row-cell-break-word {
    background: #fafafa;
  }
}

.eye-icon:hover {
  cursor: pointer;
}

.moreAssignment:hover {
  cursor: pointer;
}

::v-deep .ant-table-thead > tr > th.ant-table-row-cell-break-word {
  font-size: 18px;
  padding: 8px 8px;
}

::v-deep .ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 4px;
}

.skillslist {
  padding: 20px 0;

  .skills {
    display: flex;
    flex-wrap: wrap; //让弹性盒元素在必要的时候拆行
    //width: 1895px;
    width: 100%;
    justify-content: flex-start;

    .skill-name {
      //width: 620px;
      width: 50%;
      &.skill-name-hover {
          background: #f7fafc;
        }
      .icon {
        padding-left: 20px;
        color: #1890ff;
      }

      .del-button {
        display: none;
        font-size: 15px;
        float: right;
        padding: 10px 10px 5px 0px;
        color: #999;
      }
        .del-button-hover {
          display: block;
        }
      }
  }
}

.scrollbar {
  padding: 16px 16px 38px;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
    .base-info {
      margin-top: 50px;
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      padding: 30px 30px 40px;
      // position: relative;
    }

  .scrollMargin {
    margin: 20px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    width: 5px;
    height: 5px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

::v-deep .ant-table-header {
  background: #d8e9f9;
}

::v-deep .ant-table-body {
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    width: 13px;
    height: 13px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

::v-deep {
  .ant-table-empty {
    position: relative;
  }

  .ant-table-empty .ant-table-body {
    min-height: 175px;
  }

  .ant-table-empty .ant-table-placeholder {
    position: absolute;
    top: 45px;
  }
}

.part {
    margin-top: 16px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 20px 25px 40px;
}
  .part-title {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #2d3748;
    margin-left: 20px;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 26px;
      position: absolute;
      left: -20px;
      top: 2px;
      background: #3182ce;
      border-radius: 10px;
    }
  }
  .add-btn {
      position: absolute;
      right: 0;
      top: 0;
      .edit-button {
        width: 84px;
      }
    }

.staffing-skill {
  margin-top: -30px;
  position: relative;
  padding-top: 60px;
  .red-text{
    color: red;
    font-size: 12px;
    position: absolute;
    top: 5px;
    left: 150px;
  }
    }
</style>
